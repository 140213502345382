import React, { useRef, useState, useEffect } from 'react';
import {
  Input,
  Button as AntdButton,
  Space,
  Table,
  Modal,
  Tooltip,
} from 'antd';
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

const ManagerGroup = ({
  data,
  props,
  setShowAssignButton,
  setSelectedMemberID,
  setSelectedAdminID,
  selectedGroupID,
  setSelectedGroupID,
  setSelectedNames,
  selectedRowKeys,
  setSelectedRowKeys,
  setManagerTableLoading,
  managerGroupLoading,
  setManagerGroupLoading
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [showEdit, setShowEdit] = useState(false);
  const [editing, setEditing] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const generateDataArray = (data) => {
    if (!data) return false;
    return data.map((_data) => {
      _data.members.sort((a, b) => {
        if (a.role === b.role) {
          if (a.joiningDate !== null && b.joiningDate !== null) {
            return new Date(a.joiningDate.toDate()) - new Date(b.joiningDate.toDate())
          } else if (a.joiningDate === null && b.joiningDate !== null) {
            return 1;
          } else if (a.joiningDate !== null && b.joiningDate === null) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if (a.role === "Admin" && b.role === "Member") {
            return -1;
          }
          return 1;
        }
      });
      let adminName = '';
      if (_data.members.length > 0) {
        for (let i = 0; i < _data.members.length; i++) {
          if (_data.members[i].role === "Admin") {
            adminName += _data.members[i].profiles.default.fullName;
            adminName += ", ";
          }
        }
      }
      if (adminName.length > 0) {
        adminName = adminName.substring(0, adminName.length - 2);
      } else {
        adminName = "None";
      }
      if (_data.createTime !== null) {
        return {
          name: _data.groupName,
          key: _data.groupID,
          id: _data.groupID,
          adminName: adminName,
          number: _data.members.length,
          members: _data.members,
          createTime: new Date(_data.createTime.toDate()).toDateString()
        };
      }
      return {
        name: _data.groupName,
        key: _data.groupID,
        id: _data.groupID,
        adminName: adminName,
        number: _data.members.length,
        members: _data.members
      };
    });
  };

  const [newData, setNewData] = useState([]);
  useEffect(() => {
    // console.log("render", generateDataArray(data));
    setNewData(generateDataArray(data));
  }, [data]);

  const getGroupDetail = (record) => {
    // console.log('record', record);0
    const membersList = record.members.map((member) => {
     let image;
     if(member.profiles.default.profilePicture==='https://ramcotubular.com/wp-content/uploads/default-avatar.jpg'){
      image = '/assets/media/profile/default-avatar.jpeg'
     }else{
      image = member.profiles.default.profilePicture;
     }
      if (member.joiningDate !== null) {
        return {
          id: member.userID,
          profilePicture: image,
          Name: member.profiles.default.fullName,
          groupName: record.name,
          Email: member.profiles.default.email,
          Role: member.role,
          key: record.id + "_" + member.userID,
          groupID: record.id,
          joiningDate: new Date(member.joiningDate.toDate()).toDateString()
        };
      }
      return {
        id: member.userID,
        profilePicture: image,
        Name: member.profiles.default.fullName,
        groupName: record.name,
        Email: member.profiles.default.email,
        Role: member.role,
        key: record.id + "_" + member.userID,
        groupID: record.id,
      };
    });
   
    return membersList;
  };

  const groupNameExist = (newGroupName) => {
    for (let _data of data) {
      // console.log("123", _data.groupName.replace(/\s/g, ""), newGroupName.replace(/\s/g, ""));
      if (_data.groupName.replace(/\s/g, "").toLowerCase() === newGroupName.replace(/\s/g, "").toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  // const deleteMemberFromData = (groupID, userID, list) => {
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i].groupID === groupID) {
  //       data[i][list] = data[i][list].filter(user => user.userID !== userID);
  //     }
  //   }
  //   setNewData(generateDataArray(data));
  // }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <AntdButton
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </AntdButton>
          <AntdButton
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </AntdButton>
          <AntdButton
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </AntdButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : record.uid ? (
        <Link to={'/insights/' + record.uid} className="textLink">
          <Tooltip
            placement="top"
            title={
              dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
            }
          >
            <span>{text}</span>
          </Tooltip>
        </Link>
      ) : (
        <Tooltip
          placement="top"
          title={
            dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)
          }
        >
          <span>{text}</span>
        </Tooltip>
      ),
  });
  const groupListColumns = [

    {
      title: '',
      dataIndex: 'profilePicture',
      key: '1',
      align: 'center',
      // width: 50,
      render: (profilePicture, record) =>
        record.id ? (
          <Link to={'/insights/' + record.id}>
            <img
              src={
                profilePicture ||
                '/assets/media/profile/default-avatar.jpeg'
              }
              alt="picture"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
              }}
            />
          </Link>
        ) : (
          <img
            src={
              profilePicture ||
              '/assets/media/profile/default-avatar.jpeg'
            }
            alt="picture"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          />
        ),
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: '2',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: '3',
      align: 'center',
      // width: '25%',
    },
    {
      title: 'Joining Time',
      dataIndex: 'joiningDate',
      key: '4',
      align: 'center',
      // width: '50%',
      // ...getColumnSearchProps('joiningDate'),
      // sorter: (a, b) => a.joiningDate.length - b.joiningDate.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Role',
      dataIndex: 'Role',
      key: '5',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Actions',
      key: '6',
      align: 'center',
      render: (record) => {
        return (
          <>
            <DeleteOutlined
              onClick={() => {
                onDeleteGroupMember(record);
              }}
              style={{ color: 'red', margin: 12 }}
            />
          </>
        );
      },
    },
  ];
  const columns = [
    // {

    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: '1',

    // },

    {
      title: 'Group Name',
      dataIndex: 'name',
      key: '2',
      align: 'center',
      // render: (text) => <a onClick={() => hanldeClick()}></a>,
      // width: '30%',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Group Admin',
      dataIndex: 'adminName',
      key: '3',
      align: 'center',
      // width: '30%',
      ...getColumnSearchProps('adminName'),
    },

    {
      title: 'Member Number',
      dataIndex: 'number',
      key: '4',
      align: 'center',
      width: '18%',
      // ...getColumnSearchProps('number'),
      sorter: (a, b) => a.number - b.number,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Create Time',
      dataIndex: 'createTime',
      key: '5',
      align: 'center',
      ...getColumnSearchProps('createTime'),
    },
    {
      title: 'Actions',
      key: '6',
      align: 'center',
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                editGroupName(record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                onDeleteGroup(record);
              }}
              style={{ color: 'red', margin: 12 }}
            />
          </>
        );
      },
    },
  ];
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   'selectedRows: ', selectedRows
      // );
      if (selectedRows[0].Role === 'Admin') {
        setShowAssignButton(false);
        setSelectedAdminID(selectedRows[0].id);
      } else {
        setShowAssignButton(true);
        setSelectedMemberID(selectedRows[0].id);
      }
      setSelectedGroupID(selectedRows[0].groupID);
      setSelectedNames(selectedRows[0].Name);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) =>
    // console.log('ooo',record),
    ({
      // disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onDeleteGroup = (record) => {
    Modal.confirm({
      title: `Are you sure to delete ${record.name}?`,
      okText: 'Yes',
      okType: 'danger',
      onOk: async () => {
        setManagerTableLoading(true);
        setManagerGroupLoading(true);
        const isSucceed = await props.profile.deleteGroup(
          record.id,
          record.members
        );
        // console.log("1111", record);
        if (!isSucceed) {
          Modal.error({
            title: "There are some errors, please refresh and try again.",
          })
          // console.log("Delete group failed");
        }
      }
    });
  };
  const onDeleteGroupMember = (record) => {
    Modal.confirm({
      title: `Are you sure to remove ${record.Name} from ${record.groupName}?`,
      okText: 'Yes',
      okType: 'danger',
      onOk: async () => {
        setManagerTableLoading(true);
        setManagerGroupLoading(true);
        // console.log('rocord', record);
        if (record.Role === 'Admin') {
          const isSucceed = await props.profile.deleteAdminFromGroup(
            record.groupID,
            record.id,
          );
          if (!isSucceed) {
            Modal.error({
              title: "There are some errors, please refresh and try again.",
            })
            // console.log("Delete member failed");
          }
        } else {
          const isSucceed = await props.profile.deleteUserFromGroupIfExist(
            record.groupID,
            record.id,
          );
          if (!isSucceed) {
            Modal.error({
              title: "There are some errors, please refresh and try again.",
            })
            // console.log("Delete admin failed");
          }
        }
      },
    });
  };
  const editGroupName = (record) => {
    setShowEdit(true);
    setEditing({ ...record });
  };

  const resetEdit = () => {
    setShowEdit(false);
    setEditing(null);
  };

  return (
    <>
      <div>
        <Table
          loading={managerGroupLoading}
          columns={columns}
          dataSource={newData}
          scroll={{ x: 'calc(100% - 4em)' }}
          expandable={{
            rowExpandable: (record) => record.number >= 1,
            expandedRowRender: (record) => {
              return (
                <Table
                  rowSelection={{
                    type: "radio",
                    ...rowSelection,
                  }}
                  columns={groupListColumns}
                  dataSource={getGroupDetail(record)}
                  scroll={{ x: 'calc(100% - 120em)' }}
                />
              );
            },
            onExpand: (expended, record) => {
              // console.log("expended", expended, "record", record);
              if (!expended && record.id === selectedGroupID) {
                setSelectedAdminID(null);
                setSelectedMemberID(null);
                setSelectedRowKeys([]);
              }
            }
          }}
        />

        <Modal
          title="Edit group name"
          open={showEdit}
          okText="Save"
          confirmLoading={confirmLoading}
          onCancel={() => {
            resetEdit();
          }}
          onOk={async () => {
            // console.log("123", editing.name.length);
            if (editing.name.trim().length === 0) {
              Modal.error({
                title: "Group name is invalid.",
              })
            } else if (groupNameExist(editing.name)) {
              Modal.error({
                title: "The group name already exists, please try another one.",
              })
            } else {
              setManagerTableLoading(true);
              setManagerGroupLoading(true);
              setConfirmLoading(true);
              const isSucceed = await props.profile.updateGroupName(
                editing.name,
                editing.id,
              );
              // console.log("1111", editing.name, editing.id, isSucceed);
              if (isSucceed) {
                setConfirmLoading(false);
              } else {
                Modal.error({
                  title: "There are some errors, please refresh and try again.",
                })
                // console.log("Delete member failed");
              }
              resetEdit();
            }
          }}
        >
          <Input
            value={editing?.name}
            onChange={(e) => {
              setEditing((pre) => {
                return { ...pre, name: e.target.value };
              });
            }}
          />
        </Modal>
        {/* {show && <Modal details={GroupDetail} handleClose={hideModal} />} */}
      </div>
    </>
  );
};
export default ManagerGroup;