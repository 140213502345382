import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Modal } from 'antd';
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
import { compose } from 'recompose';
import { fadeInUp } from 'react-animations';
import {
  AuthDataContext,
  withAuthDataContext,
} from '../../components/Firebase/authdataprovider';
import InsightsSideBar from '../Insights/InsightsSideBar';
import Layout from '../../components/Layout';
import AppConfig from '../../components/App/AppConfig';
import { withAuthorization } from '../../components/Session';
import ManagerTable from '../../components/ManagerTable/ManagerTable';
import ManagerGroup from '../../components/ManagerGroup/ManagerGroup';
import CompanyInfo from '../../components/CompanyInfo';
import * as ROUTES from '../../constants/routes';
import FixedCeilingComponent from '../../components/FixedCeilingComponent/FixedCeilingComponent';
import '../ManagerPage/ManagerPage.css'
import f from 'firebase';
import CompanyBusinessValue from '../../components/CompanyBusinessValue/CompanyBusinessValue';
const FadeUp = styled.div`
  animation: 0.6s ${keyframes`${fadeInUp}`};
`;
// const LoadingBox = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   margin: 0 auto;
// `;

const ManagerPage = (props) => {
  const [companyMembersList, setCompanyMembersList] = useState([]);
  const [selectedMembersID, setSelectedMembersID] = useState([]);
  const [selectedMemberID, setSelectedMemberID] = useState(null);
  const [selectedAdminID, setSelectedAdminID] = useState(null);
  const [selectedGroupID, setSelectedGroupID] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [selectedNames, setSelectedNames] = useState(null);
  const [isUserList, setIsUserList] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [showAssignButton, setShowAssignButton] = useState(true);
  // const [value, setValue] = useState('1');
  const [companyInfo, setCompanyInfo] = useState(null);
  const [companyBusinessValue, setCompanyBusinessValue] = useState(null);
  const [selectedRadioRowKeys, setSelectedRadioRowKeys] = useState([]);
  const [selectedCheckBoxRowKeys, setSelectedCheckBoxRowKeys] = useState([]);
  const [managerTableLoading, setManagerTableLoading] = useState(true);
  const [managerGrouploading, setManagerGroupLoading] = useState(true);
  // const [expandableManagerGrouploading, setExpandableManagerGroupLoading] = useState(true);
  // const [documentRef, setDocumentRef] = useState(null);
  useEffect(() => {
    if (
      props.profile.data?.company?.id &&
      props.profile.data?.company?.id !== ''
    ) {
      // console.log("render");
      window.scrollTo(0, 0);
      // getCompanyMembersList(props.profile.data?.company?.id);
      // getCompanyGroupList(props.profile.data?.company?.id);
      loadCompanyInfo();
      loadCompanyBusinessValue();
      const db = f.firestore();
      var unsubscribeGroups = db.collection("groups")
        .where("companyID", "==", props.profile.data?.company?.id)
        .onSnapshot((querySnapshot) => {
          // console.log("123", querySnapshot.metadata.hasPendingWrites);
          // if (!querySnapshot.metadata.hasPendingWrites) {
          getCompanyMembersList(props.profile.data?.company?.id);
          getCompanyGroupList(props.profile.data?.company?.id);
          // }
        });
      var unsubscribeGroupMember = db.collection("junction_group_member")
        .where("companyID", "==", props.profile.data?.company?.id)
        .onSnapshot((querySnapshot) => {
          getCompanyMembersList(props.profile.data?.company?.id);
          getCompanyGroupList(props.profile.data?.company?.id);
        })
      var unsubscribeUsers = db.collection("users")
        .where("company.id", "==", props.profile.data?.company?.id)
        .onSnapshot((querySnapshot) => {
          getCompanyMembersList(props.profile.data?.company?.id);
        });
      return () => {
        unsubscribeGroups();
        unsubscribeGroupMember();
        unsubscribeUsers();
      };
    }
  }, []);

  const getCompanyMembersList = async (companyID) => {
    try {
      const companyUsers = await props.profile.getAllUsersByCompany(companyID);
      if (companyUsers === false) {
        throw new Error("Error getting companyUsers.");
      }
      const promises = [];
      const groupLists = [];
      for (let i = 0; i < companyUsers.length; i++) {
        promises.push(props.profile.getGroupsByUser(companyID, companyUsers[i].id));
      }
      for await (let groupList of promises) {
        if (groupLists !== false) {
          groupLists.push(groupList);
        } else {
          throw new Error("Error getting groupLists.");
        }
      }
      // console.log("userLists", userLists);
      for (let i = 0; i < companyUsers.length; i++) {
        companyUsers[i].groups = groupLists[i];
      }
      setCompanyMembersList(companyUsers);
      setManagerTableLoading(false);
      // console.log('companyUsers：', companyUsers);
    } catch (error) {
      console.log('Error: ', error);
      return false;
    }
  };

  const getCompanyGroupList = async (companyID) => {
    try {
      const companyGroups = await props.profile.getGroupsByCompany(companyID);
      if (companyGroups === false) {
        throw new Error("Error getting companyGroups.");
      }
      const promises = [];
      const userLists = [];
      for (let i = 0; i < companyGroups.length; i++) {
        promises.push(props.profile.getMembersByGroup(companyGroups[i].groupID));
      }
      for await (let userList of promises) {
        if (userLists !== false) {
          userLists.push(userList);
        } else {
          throw new Error("Error getting userLists.");
        }
      }
      // console.log("userLists", userLists);
      for (let i = 0; i < companyGroups.length; i++) {
        companyGroups[i].members = userLists[i];
      }
      setGroupList(companyGroups);
      setManagerGroupLoading(false);
      // console.log('companyGroups：', companyGroups);
    } catch (error) {
      console.log('Error: ', error);
      return false;
    }
  };

  const loadCompanyInfo = async () => {
    try {
      const result = await props.profile.getCompanyInfo(
        props.profile.data?.company?.id
      );
      if (result) {
        setCompanyInfo(result.company.data)
      }
    } catch (error) {
      console.log(`Error in loadCompanyInfo ${error}`);
    }
  };

  const loadCompanyBusinessValue = async () => {
    try {
      const result = await props.profile.getCompanyBusinessValue(
        props.profile.data?.company?.id
      );
      if (result) {
        setCompanyBusinessValue(result)
      }
    } catch (error) {
      console.log(`Error in loadCompanyBusinessValue ${error}`);
    }
  };

  const assignUsersToGroup = async (companyID, selectedMembersID, selectedGroupID) => {
    let names = "";
    for (let i = 0; i < selectedNames.length; i++) {
      names += selectedNames[i];
      if (i !== selectedNames.length - 1) {
        names += ", ";
      }
    }
    Modal.confirm({
      title: `Are you sure to assign ${names} to ${selectedGroupName}?`,
      okText: 'Yes',
      okType: 'danger',
      onOk: async () => {
        setManagerTableLoading(true);
        setManagerGroupLoading(true);
        const isSucceed = await props.profile.assignMultipleUsersToGroup(companyID, selectedGroupID, selectedMembersID);
        if (isSucceed) {
          // await getCompanyMembersList(props.profile.data?.company?.id);
          // await getCompanyGroupList(props.profile.data?.company?.id);
          setSelectedMembersID([]);
          setSelectedCheckBoxRowKeys([]);
        } else {
          Modal.error({
            title: "There are some errors, please try again.",
          })
        }
      }
    });
  }

  const assignUserAsAdminToGroup = async (groupID, memberID) => {
    Modal.confirm({
      title: `Are you sure to assign ${selectedNames} as admin?`,
      okText: 'Yes',
      okType: 'danger',
      onOk: async () => {
        setManagerTableLoading(true);
        setManagerGroupLoading(true);
        const isSucceed = await props.profile.assignUserAsAdminToGroup(groupID, memberID);
        if (isSucceed) {
          // await getCompanyGroupList(props.profile.data?.company?.id);
          setSelectedMemberID(null);
          setSelectedGroupID(null);
          setSelectedRadioRowKeys([]);
        } else {
          Modal.error({
            title: "There are some errors, please try again.",
          })
        }
      }
    });
  }

  const revokeAdminFromGroup = async (groupID, adminID) => {
    Modal.confirm({
      title: `Are you sure to revoke ${selectedNames}'s admin privilege?`,
      okText: 'Yes',
      okType: 'danger',
      onOk: async () => {
        setManagerTableLoading(true);
        setManagerGroupLoading(true);
        const isSucceed = await props.profile.revokeAdminFromGroup(groupID, adminID);
        if (isSucceed) {
          // await getCompanyGroupList(props.profile.data?.company?.id);
          setSelectedAdminID(null);
          setSelectedGroupID(null);
          setSelectedRadioRowKeys([]);
        } else {
          Modal.error({
            title: "There are some errors, please try again.",
          })
        }
      }
    });
  }

  const createGroup = async (groupName, companyID) => {
    Modal.confirm({
      title: 'Are you sure to create a new group?',
      okText: 'Yes',
      okType: 'danger',
      onOk: async () => {
        setManagerTableLoading(true);
        setManagerGroupLoading(true);
        const groupID = await props.profile.createGroup(
          groupName,
          companyID,
        );
        if (groupID !== false) {
          // await getCompanyGroupList(props.profile.data?.company?.id);
        } else {
          Modal.error({
            title: "There are some errors, please try again.",
          })
        }
      }
    });
  };

  // const updateGroupList = (groupInfo) => {
  //   // console.log("before", groupList);
  //   let updated = []
  //   for (let i = 0; i < groupList.length; i++) {
  //     if (groupList[i].groupID === groupInfo.groupID) {
  //       // updated = [...updated, groupInfo];
  //       updated.push(groupInfo);
  //     } else {
  //       // updated = [...updated, groupList[i]];
  //       updated.push(groupList[i]);
  //     }
  //   }
  //   // console.log("after", updated);
  //   setGroupList([...updated]);
  // }

  // const generateGroupDropdown = (groupList) => {
  //   return groupList.map((group) => {
  //     return {
  //       value: group.groupID,
  //       label: group.groupName,
  //     };
  //   });
  // };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const onSearch = (value) => {
  //   // console.log('search:', value);
  // };
  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };

  return (
    <>
      <div className="insightsPage">
        <Layout>
          <div className="insightsBox">
            <AuthDataContext.StreamConsumer requireAccount={true}>
              {(profile) => (
                <>
                  <InsightsSideBar
                    image={
                      profile.data.profiles.default.profilePicture
                    }
                    name={profile.data.profiles.default.fullName}
                    profile={profile}
                  />
                  <div className="wrapper">
                    <FadeUp className="my-auto">
                      <div className="company">
                        <div className="title">
                          <span>
                            <b>{isUserList ? "Company Members List" : "Company Groups List"}</b>
                          </span>
                        </div>
                        <section>
                          <FixedCeilingComponent
                            props={props}
                            isUserList={isUserList}
                            setIsUserList={setIsUserList}
                            selectedAdminID={selectedAdminID}
                            setSelectedAdminID={setSelectedAdminID}
                            selectedMemberID={selectedMemberID}
                            setSelectedMemberID={setSelectedMemberID}
                            selectedGroupID={selectedGroupID}
                            setSelectedGroupID={setSelectedGroupID}
                            setSelectedGroupName={setSelectedGroupName}
                            setSelectedRadioRowKeys={setSelectedRadioRowKeys}
                            selectedMembersID={selectedMembersID}
                            setSelectedMembersID={setSelectedMemberID}
                            setSelectedCheckBoxRowKeys={setSelectedCheckBoxRowKeys}
                            assignUserAsAdminToGroup={assignUserAsAdminToGroup}
                            assignUsersToGroup={assignUsersToGroup}
                            revokeAdminFromGroup={revokeAdminFromGroup}
                            createGroup={createGroup}
                            showAssignButton={showAssignButton}
                            groupList={groupList} />
                          {isUserList ? (
                            <ManagerTable
                              data={companyMembersList}
                              props={props}
                              setSelectedMembersID={setSelectedMembersID}
                              setSelectedNames={setSelectedNames}
                              selectedRowKeys={selectedCheckBoxRowKeys}
                              setSelectedRowKeys={setSelectedCheckBoxRowKeys}
                              setManagerTableLoading={setManagerTableLoading}
                              managerTableLoading={managerTableLoading}
                            />
                          ) : (
                            <ManagerGroup
                              data={groupList}
                              props={props}
                              setShowAssignButton={setShowAssignButton}
                              setSelectedMemberID={setSelectedMemberID}
                              setSelectedAdminID={setSelectedAdminID}
                              selectedGroupID={selectedGroupID}
                              setSelectedGroupID={setSelectedGroupID}
                              setSelectedNames={setSelectedNames}
                              selectedRowKeys={selectedRadioRowKeys}
                              setSelectedRowKeys={setSelectedRadioRowKeys}
                              setManagerTableLoading={setManagerTableLoading}
                              managerGroupLoading={managerGrouploading}
                              setManagerGroupLoading={setManagerGroupLoading}
                            />
                          )}
                        </section>
                      </div>
                      <div className="company">
                        <div className="title">
                          <span>
                            <b>Company Info</b>
                          </span>
                        </div>
                        <div className="company-info">
                          {companyInfo && (
                            <CompanyInfo
                              profile={profile}
                              info={companyInfo}
                              companyId={props.profile.data?.company?.id}
                            />
                          )}
                        </div>
                      </div>
                      <div className="company">
                        <div className="title">
                          <span>
                            <b>Company Business Value</b>
                          </span>
                        </div>
                        <div className="company-info">
                          {companyBusinessValue && (
                            <CompanyBusinessValue
                              profile={profile}
                              info={companyBusinessValue}
                              companyId={props.profile.data?.company?.id}
                            />
                          )}
                        </div>
                      </div>
                      <div className="company">
                        <section>
                          <div className="row m-0 ">
                            <div className="col-md-6 pt-3 pb-3">
                              <button
                                className="button btn-solid-purple createBtnBottom"
                                onClick={() =>
                                  props.history.push(
                                    ROUTES.VIBEPAGE,
                                  )
                                }
                              >
                                TEAM'S VIBE
                              </button>
                            </div>
                            <div className="col-md-6 pt-3 pb-3 btn-right-corner-innovation">
                              <button
                                className="button btn-solid-purple createBtnBottom"
                                onClick={() =>
                                  props.history.push(
                                    ROUTES.INNOVATION_PAGE,
                                  )
                                }
                              >
                                TEAM'S INNOVATION
                              </button>
                            </div>
                          </div>
                        </section>
                      </div>
                    </FadeUp>
                  </div>
                </>
              )}
            </AuthDataContext.StreamConsumer>
          </div>
        </Layout>
      </div>
    </>
  );
};
const Manager_Page = withAuthDataContext(ManagerPage);

const condition = (profile) => {
  // console.log("JObs mode? Verified? ", AppConfig.isJobsMode(), profile.emailVerified)
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(withAuthorization(condition))(Manager_Page);