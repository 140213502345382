import React, { useState }  from 'react'
import { PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Button } from 'react-bootstrap';

import './NewGraph.scss'
ChartJS.register(ArcElement, Tooltip, Legend);

const NewGraph = ({sociability, 
    steadiness,
    creativity, 
    persistence, 
    cooperativeness, 
    profile, 
    viewer, 
    chosenTraits}) => {
   
   
    const [traits, setTraits] = useState(chosenTraits?chosenTraits:[])

        
    const chartData = {
        labels: [
                'Leadership', 
                'Empathetic', 
                'Collaborative', 
                'Entrepreneurialism', 
                'Mentorship'
            ],
        datasets: [
            {
            label: 'Collaborative Traits',
            data: [sociability, steadiness,creativity, persistence, cooperativeness],
            backgroundColor: [
                'rgba(52, 152, 219, 0.7)',
                'rgba(39, 174, 96, 0.7)',
                'rgb(231, 76, 60, 0.7)',
                'rgba(142, 68, 173, 0.7)',
                'rgba(241, 196, 15, 0.7)',
                
            ],
            circular: true,
            
            
            
            
            }]
};
const chartOptions = {
    onClick: (_, elements) => handleClick(elements),
    

    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 3
      }

    },
    layout: {
      padding: {
        left: 20,
        right: 20
      },

    }
  };
  const handleResetTraits =(e)=>{

    e.preventDefault();
    alert("Highlighted Traits Reset")
    setTraits([])
    profile.data.profiles.default.insights.chosenTraits=[];
    profile.save();
  }

  const handleClick = (elements) =>{
    if(profile?.uid!==viewer) return;
    
    const clickedElement = elements[0];
    const label = chartData.labels[clickedElement.index];
   
    if(profile?.getDefaultProfile().insights?.chosenTraits?.length === 3){
      if(traits.length<2) setTraits([...traits, label])
      alert("You cannot highlight more than 3 traits")
      return;
    
    }

      if(!traits.includes(label) && !profile?.data?.profiles?.default?.insights?.chosenTraits?.includes(label)){
        alert(`Setting ${label} to your chosen traits`)
        if(traits!==''){
          setTraits([...traits, label])
          profile.getDefaultProfile().insights.chosenTraits= [...traits, label];
          profile.save();
        }else{
          setTraits([label])
          profile.getDefaultProfile().insights.chosenTraits= [label]
          profile.save();
        }
    
  }else{
    alert(`${label} already set as chosen trait`)
  }
  
      
      
 


  }



  return (
    <div className="colabTraits">
        <div className="title">
            <span><b>Collaborative Traits</b></span>
        </div>
        <div className="traitsWrapper"> 
            <div className="traits-container">
                <PolarArea data={chartData} options={chartOptions} />
                <section className="labels">
                    <div className="mentor">Mentorship</div>
                    <div className="empath">Empathetic</div>
                    <div className="leader">Leadership</div>
                    <div className="entrepreneurial">Entrepreneurialism</div>
                    <div className="risk-taker">Collaborative</div>
                </section>
            </div>
            <div className='footer-items'>
            {(profile?.uid==viewer)? 
            <Button
                className= "reset-traits-btn"
                onClick={handleResetTraits}
                style={{maxWidth: "200px"}}>Reset Highlighted Traits</Button>:''}
              <i className="Recommendations">Recommendation based on the user inputs in the My Reflections section of the user's profile </i>
              <span className="Powered">Powered by KeeperAI</span>
            </div>
        </div>
       
    </div>
  )
}
export default NewGraph;