import React, { Component } from 'react';
import colors from 'hex-to-color-name';
import { Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Redirect } from 'react-router-dom';
// import { fadeInDown, fadeInUp } from 'react-animations';
import { compose } from 'recompose';
// import styled, { keyframes } from 'styled-components';

import Cards from '../../components/Cards';
import Layout from '../../components/Layout';
import ProfileHeader from '../../components/ShowProfile/ProfileHeader';
import { withAuthDataContext } from '../../components/Firebase/authdataprovider';
import AppTemplates from '../../constants/apptemplates';
import AshSmith from '../../constants/ashleySmith';
import AshleySmithTeams from '../../constants/ashleySmithTeam';
import Reader from '../../components/PDFReader/Reader';
import * as ROUTES from '../../constants/routes';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import AppConfig from '../../components/App/AppConfig'
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import { withAuthorization } from '../../components/Session';

import NewGraph from '../Insights/NewGraph';
import WordCloud from '../Insights/WordCloud';
import YourColor from '../Insights/YourColor';
import MissionStatement from '../Insights/MissionStatement';

// const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
// const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;
class ProfileBase extends Component {
  constructor(props) { 
    super(props);    

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.state = {
      user: null,
      loading: false,
      userProfile: null,
      isMyProfile: false,
      showAbbreviatedSignIn: true,
      sociabilityModal: false,
      steadinessModal: false,
      creativityModal: false,
      persistenceModal: false,
      cooperativenessModal: false,
      sociabilityInputModal: false,
      steadinessInputModal: false,
      creativityInputModal: false,
      persistenceInputModal: false,
      cooperativenessInputModal: false,
      mode: "job",
      profileId: "",
      name: '',
      team: '',
      job: '',
      sociability: '',
      steadiness: '',
      creativity: '',
      persistence: '',
      cooperativeness: '',
      shapeTheFuture: '',
      deepResearch: '',
      earnCustomerLoyalty: '',
      creativityOriginality: '',
      affinityGroups: '',
      businessValue: {},
      words: [],
      options: {
        enableTooltip: true,
        fontSizes: [20,60],
        padding: 1,
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
     
      },
      color: 'blue',
      theme: null,
    };
  };
  static defaultProps = {
    match: '',
  }


  componentDidMount = async () => {
    this.setState({ loading: true })

    if (AppConfig.isJobsMode()) {
      this.setState({ mode: 'job' })
    } else {
      this.setState({ mode: 'team' });
    }
    let profileId = null;
    try {
      profileId = this.props.match.params.id;
      let currentUser = this.props.profile.currentUser;
      if (currentUser && currentUser.uid === profileId) {
        this.setState({ isMyProfile: true });
      }
    } catch (exception) {
      // console.log(exception);
    }
    if (profileId === "ashsmith") {
      if (AppConfig.isJobsMode()) {
        this.setState({ mode: 'job', userProfile: AshSmith.profiles.default })
      } else {
        // console.log('team');
        this.setState({ mode: 'team', userProfile: AshleySmithTeams.profiles.default, profileId: profileId });
      }
    }
    else {
      let profile = null;
      try {
        profile = await this.props.profile.getEntireUser(profileId);
        console.log("Getting profile: ", profile);
        if (profile === null) {
          profile = 'noprofile';
        }
      } catch (exception) {
        profile = 'noprofile';
      };
      
      this.setState({ userProfile: profile.profiles.default, user: profile });
      const userData = profile.profiles.default?.insights;
      console.log(profile, 'hi')
      let colorName;
      if (userData) {
        if (userData?.color === null || userData?.color === undefined) {
          colorName = 'red';
        } else {
          colorName = this.convertHexToRBG(userData?.color);
        }
        // set state with results of getInsights() API call
        this.setState({
          loading: false,
          color: colorName,
          words: userData.wordCloud,
          sociability: userData.fiveFactorsScores.Sociability,
          steadiness: userData.fiveFactorsScores.Steadiness,
          creativity: userData.fiveFactorsScores.Creativity,
          persistence: userData.fiveFactorsScores.Persistence,
          cooperativeness: userData.fiveFactorsScores.Cooperativeness,
          theme: userData.theme,
          shapeTheFuture: userData.fiveFactorsScores.Creativity + userData.fiveFactorsScores.Persistence,
          deepResearch: userData.fiveFactorsScores.Creativity + userData.fiveFactorsScores.Cooperativeness,
          earnCustomerLoyalty: userData.fiveFactorsScores.Cooperativeness + userData.fiveFactorsScores.Sociability,
          creativityOriginality: userData.fiveFactorsScores.Creativity + userData.fiveFactorsScores.Persistence

        });
      }
    }
  }
  convertHexToRBG (hex) {
    console.log(hex)
    const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
    
    const result = {
        r: parseInt(m[1], 16),
        g: parseInt(m[2], 16),
        b: parseInt(m[3], 16)
    };
    console.log(result, "result")
    if(result.r > result.g && result.r > result.b ) return 'red';
    else if(result.g > result.r && result.g > result.b ) return 'green';
    else if(result.b > result.r && result.b > result.g) return 'blue';
    else return 'blue'
  }

  // handleClose for modal
  handleClose(e) {
    console.log(e.target.value)
    this.setState({
      [e.target.value]: false
    });
  }
  // handleShow for modal
  handleShow(e) {
    console.log(e.target.value)
    this.setState({
      [e.target.name]: true
    });
  }

  UserProfile = () => {
    const { userProfile, isMyProfile } = this.state;
    

    const chartData =
    {
      labels: ['Shape The Future', 'Earn Customer Loyalty', 'Creativity & Originality', 'Deep Research'],
      datasets: [
        {
          label: 'Your Profile',
          data: [
            this.state.shapeTheFuture,
            this.state.earnCustomerLoyalty,
            this.state.creativityOriginality,
            this.state.deepResearch
          ],
          backgroundColor: 'rgba(100, 62, 166, 0.5)',
          borderColor: 'rgba(100, 62, 166, 1)',
          borderWidth: 1,
        },
      ],
    };
    //chartOptions object for Mission Statement & Values component
    const chartOptions = {

      scales: {
        r: {
          suggestedMin: 0,
          suggestedMax: 6
        }

      },
      layout: {
        padding: {
          left: 20,
          right: 20
        },

      }
    };
    let image;
    if(userProfile?.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"){
      image = '/assets/media/profile/default-avatar.jpeg'
    }else{
      image = userProfile?.profilePicture;
    }
  
    return (
      <div className='MyProfile'>
        <ProfileHeader
          user={null}
          review={false}
          userProfile={userProfile}
          match={this.props.match}
          isMyProfile={this.props.isMyProfile}
        />
        <Container>
          <div>
            <div className='render-attributes'>
              <Cards
                flip={false}
                edit={false}
                template={AppTemplates.default.softSkills}
                softSkillsAnswers={userProfile.softSkillsAnswers}
                profile={null} />
            </div>
            {userProfile.resumeURL &&
              <Reader resumeURL={userProfile.resumeURL} isMyProfile={isMyProfile} />
            }
          </div>
        </Container>
        <div className='fivefactors_container'>
          <div id="fivefactors">
           
            <NewGraph 
                              sociability={this.state.sociability}
                              steadiness={this.state.steadiness}
                              creativity={this.state.creativity}
                              persistence={this.state.persistence}
                              cooperativeness={this.state.cooperativeness}
                              profile={"asdffdasdfda"}
                              chosenTraits={this.state.chosenTraits}
                              viewer={"asdfdasdf"} />
          </div>
          {/* {(this.state.user.company.data.name !== "open") && 
          <span id="missionstatement">
            <MissionStatement chartData={chartData} chartOptions={chartOptions} profile={this.state.user} businessValue={this.state.businessValue}/>
          </span>} */}
          {/* <span id="yourcolor">
            <YourColor
              color={this.state?.color}
              name={userProfile?.fullName}
            />
          </span> */}
          <span id="wordcloud">
            {/* <WordCloud
              name={userProfile?.fullName}
              words={this.state.words}
              options={this.state.options}
            /> */}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { user, userProfile, mode, profileId } = this.state;
    if (!userProfile) {
      return (
        <Layout showAbbreviatedSignIn={this.state.showAbbreviatedSignIn}>
          <ScrollTopOnPageLoad />
          <div className='spinner-container d-flex justify-content-center'>
            <Spinner animation='border' role='status'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          </div>
        </Layout>
      );
    }
    if (userProfile === 'noprofile') {
      return <Redirect to={ROUTES.FOF} />
    }
    

    return (
      <Layout showGDPR={false} showAbbreviatedSignIn={this.state.showAbbreviatedSignIn}>
        <AuthDataContext.StreamConsumer>
          {profile => (
            mode === "team" ? (
              <div>
                {/* {console.log("profileId", profileId)}
                {console.log("profile", profile.data)}
                {console.log("userProfile", userProfile)} */}
                {(profile && profile.data && profile.data.company && profile.data.company.id &&
                  user && user.company && user.company.id &&
                  profile.data.company.id === user.company.id) || (profileId === "ashsmith") ?
                  (
                    <>
                      <this.UserProfile />
                    </>
                  ) : (
                    <div>
                      <Redirect to={ROUTES.FOF} />
                    </div>
                  )}
              </div>
            ) : (
              <this.UserProfile />
            )
          )}
        </AuthDataContext.StreamConsumer>
      </Layout>
    );
  }
}

const Profile = withAuthDataContext(ProfileBase);

const condition = (profile) => {
  if (AppConfig.isJobsMode()) {
    return true;
  }

  return profile.uid !== null && profile.emailVerified === true;
}

export default compose(
  withAuthorization(condition),
)(Profile);
