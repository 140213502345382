/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import Notiflix from 'notiflix';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { compose } from 'recompose';
import FontAwesome from 'react-fontawesome';
import InsightsSideBar from './InsightsSideBar';
import colors from 'hex-to-color-name';
import { Spinner, Modal } from 'react-bootstrap';
import { fadeInDown, fadeInUp } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { Redirect, } from 'react-router-dom';
import './Insights.scss';
import AshTeam from '../../constants/ashleySmithTeam.json';
import AppTemplates from '../../constants/apptemplates.json';
import { getBirthdayMonth, getToday, getJoiningYears, getInsightsFromID, updateYRCIProfile } from '../../utils';
import { RANDOM_PROFILE_PLACEHOLDER } from '../../constants/utilConstant';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import { withAuthorization } from '../../components/Session';
import AppConfig from '../../components/App/AppConfig';
import MissionStatement from './MissionStatement';
import AffinityGroups from './AffinityGroups';
import Innovations from './Innovations/index';
import Layout from '../../components/Layout';
import Cards from '../../components/Cards';
import FiveFactors from './FiveFactors';
import FourOFour from '../FourOFour';
import WordCloud from './WordCloud';
import YourColor from './YourColor';
import MyVibe from './MyVibe';
import DailyQuestion from './DailyQuestion';
import NewGraph from './NewGraph';

const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;

const INITIAL_STATE = {
  userId: '',
  userData: {},

};



class InsightsPage extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleColorSubmit = this.handleColorSubmit.bind(this);
    this.handleInfoSubmit=this.handleInfoSubmit.bind(this);

    this.state = {
      ...INITIAL_STATE,
      redirectRoute: null,
      loading: true,
      sociabilityModal: false,
      steadinessModal: false,
      creativityModal: false,
      persistenceModal: false,
      cooperativenessModal: false,
      sociabilityInputModal: false,
      steadinessInputModal: false,
      creativityInputModal: false,
      persistenceInputModal: false,
      cooperativenessInputModal: false,
      dailyQuote: 'I think, therefore I am.',
      dailyQuoteAuthor: 'René Descartes',
      colorModal: false,
      infoModal: false,
      textArea: '',
      attributesLimit: 500,
      profilePic: '',
      name: '',
      team: '',
      job: '',
      sociability: '',
      steadiness: '',
      creativity: '',
      persistence: '',
      cooperativeness: '',
      shapeTheFuture: '',
      deepResearch: '',
      earnCustomerLoyalty: '',
      creativityOriginality: '',
      affinityGroups: AshTeam.profiles.default?.affinityGroups.groups,
      mode: 'job',
      words: [],
      options: {
        enableTooltip: true,
        fontSizes: [20, 60],
        padding: 1,
        scale: 'sqrt',
        spiral: 'archimedean',
        transitionDuration: 1000

      },
      color: '',
      value: '',
      theme: null,
      dailyAnswer: this.props.profile.data.profiles.default.dailyQuestions?.dailyAnswer,
      dailyQuestion: [],
      showDailyQuestion: false,
      isAnswered: false,
      businessValue: {},
      chosenTraits: this.props.profile.data.profiles.default.insights?.chosenTraits,

    };

  }
  
  static defaultProps = {
    match: '',
    id: '',
    title: '',
    sub: '',
  }

  // async fetchValidDailyQuote() {
  //   let response = await fetch('https://api.quotable.io/random');

  //   // Filtering quotes from Joseph Stalin
  //   while (response.author === 'Joseph Stalin') {
  //     response = await fetch('https://api.quotable.io/random');
  //   }

  //   // Fixing incorrect quote-author pair for quote_id: r9VIoOzFdUGU
  //   if (response._id === 'r9VIoOzFdUGU') {
  //     response.author = 'Seneca the Elder';
  //   }

  //   return response;
  // }

  // async getDailyQuote() {
  //   // To test the quote with incorrect quote-author pair, use quote_id: r9VIoOzFdUGU
  //   // const response = await fetch(
  //   //   'https://api.quotable.io/quotes/r9VIoOzFdUGU',
  //   // );
  //   const response = await this.fetchValidDailyQuote();
  //   return await response.json();
  // }

  // handleClose for modal
  handleClose(e) {
    this.setState({
      [e.target.value]: false
    });
  }
  // handleShow for modal
  handleShow(e) {
    this.setState({
      [e.target.name]: true
    });
  }

  // handle change for change of color
  handleColorChange(e) {
    this.setState({ value: e.target.value });
  }

  // handle submit of color change
  handleColorSubmit(e) {
    e.preventDefault();
    const { value } = this.state;
   
    this.props.profile.getDefaultProfile().insights.color = value;
    
    this.props.profile.save();
    this.setState({ color: value, colorModal: false });

  }
  handleTextAreaChange = (e)=> {
    let text = 
      e.target.value.substring(0, 
        Math.min(e.target.value.length, this.state.attributesLimit));
    this.setState({
      textArea: text,
    });
    
  };

  /// returns the number of characters left that a user can 
  /// input for each atttribute
  returnattributesLimit(){
    let Limit = this.state.attributesLimit
    try{
      Limit = this.state.attributesLimit - this.state.textArea.length;
    } catch(e){}
    
    return Limit    
  };
  handleInfoSubmit(e){
    e.preventDefault();
    const { textArea } = this.state;
    this.props.profile.getDefaultProfile().insights.moreInfo = textArea;
    this.props.profile.save();
    this.setState({ textArea: textArea, infoModal: false });
  }

  async componentDidMount() {
  
   
    
    // await this.getDailyQuote()
    //   .then(response => {

    //     this.setState({
    //       dailyQuote: response.content,
    //       dailyQuoteAuthor: response.author
    //     })
    //   })

    const groupsID = await this.props.profile.getGroupsIDByUser(this.props.profile.data.uid);
    // check if the loggedin user has insights in firebase && check the profile page is of the loggedin user 
    // -- if not then save it to firebase
    await this.props.profile.getDailyQuestionForUser(this.props.profile.data.company.id)
      .then(_res => {
        return _res.filter((r) => {
          return r.data.group_id === undefined || groupsID.includes(r.data.group_id);
        })
      })
      .then(_res => {
        if (_res.length > 0) {
          const todayQuestion = _res.reduce((a, b) => moment(a.dateTime).isAfter(moment(b.dateTime)) ? a : b);
          if (moment(todayQuestion.dateTime).date() === moment().date()) {
            let isAnswered = false;
            if (todayQuestion.data.responses.length > 0) {
              const userAnswer = todayQuestion.data.responses.find(f => f.userId === this.props.profile.uid);
              isAnswered = userAnswer ? true : false;
            }
            this.setState({
              dailyQuestion: todayQuestion,
              showDailyQuestion: true,
              isAnswered
            });
          }

        }
      });

    if (this.props.profile.data?.company?.id) {
      await this.props.profile.getCompanyBusinessValue(this.props.profile.data?.company?.id)
        .then(businessValue => {
          this.setState({
            businessValue: businessValue
          });
        })
    }

    if ((this.props.profile.uid === this.props.match.params.id) && !this.props.profile.data.profiles.default?.insights?.color) {
      await getInsightsFromID(this.props.profile.uid)
        .then(async (result) => {
          this.userData = result.insights;
          console.log('postprofile ', result, ' this.props.profile.data ', this.props.profile.data);

          if (this.props.profile.data.company.name === 'YRCI' && this.props.profile.data.profiles?.default?.profilePicture) {
            if (result.identifiers.profile_picture === 'picture location 1' || result.insights?.colors === 'FF0000') {
              const data = {
                user_id: this.props.profile.uid,
                color: '#FF0000',
                profile_picture: this.props.profile.data.profiles?.default?.profilePicture
              };
              console.log('api call with :: ', data);
              updateYRCIProfile(data);
            }
          }

          if (!this.props.profile.data.profiles.default.insights) {
            this.props.profile.data.profiles.default.insights = {};
          }
   
          try {
            if (result.insights?.color) {
              this.props.profile.data.profiles.default.insights.color = result.insights?.color;
            }

          } catch (error) {
            console.log('error ', error);
          }
          try {
            if (result.insights.mission_statement) {
              this.props.profile.data.profiles.default.insights.missionStatement = result.insights?.mission_statement;
            }

          } catch (error) {
            console.log('error ', error);
          }
          try {
            if (result.insights.theme) {
              this.props.profile.data.profiles.default.insights.theme = result.insights?.theme;
            }

          } catch (error) {
            console.log('error ', error);
          }
          try {
            this.props.profile.data.profiles.default.insights.wordCloud = result.insights?.word_cloud;
          } catch (error) {
            console.log('error ', error);
          }
          try {
            this.props.profile.data.profiles.default.insights.fiveFactorsScores = result.insights?.five_factor_scores;
          } catch (error) {
            console.log('error ', error);
          }
          this.props.profile.save();
          this.updateData();
        })
        .catch(err=>{ 
          this.setState({ loading: false } )
         } )
        .finally(console.log('done'));
    } else {
      console.log('insights');
      this.updateData();
    }




  }
  convertHexToRBG(hex) {
    
    const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);

    const result = {
      r: parseInt(m[1], 16),
      g: parseInt(m[2], 16),
      b: parseInt(m[3], 16)
    };
 
    if (result.r > result.g && result.r > result.b) return 'red';
    else if (result.g > result.r && result.g > result.b) return 'green';
    else if (result.b > result.r && result.b > result.g) return 'blue';
    else return 'blue';
  }
  async updateData() {
    const id = this.props.match.params.id;
    let colorName;
    let rgbName;

    this.setState({ loading: true });
    await this.props.profile.getCompanyUser(id)
      .then(result => {
        if (result && result.data && Object.keys(result.data).length !== 0) {
          this.setState({ profile: result.data });
        } else {
          this.setState({ profile: false });
        }

        // setLoading(false);
      });
    // need to add catch block
    // API call to our back end to get insights values for logged in user
    if (this.state.profile && this.state.profile.data) {
      const userData = this.state.profile.data.profiles.default?.insights;

      if (userData) {
        console.log(userData?.color);
        if (!userData?.color || userData?.color === null || userData?.color === undefined) {
          colorName = 'red';
          rgbName = 'red';
        } else {
          // rgbName = this.convertHexToRBG(userData?.color);
          colorName = await colors(userData?.color);
          console.log(colorName)

        }
        // set state with results of getInsights() API call
        this.setState({
          loading: false,
          color: userData.color,
          chosenTraits: userData.chosenTraits,
          words: userData.wordCloud,
          sociability: userData.fiveFactorsScores.Sociability,
          steadiness: userData.fiveFactorsScores.Steadiness,
          creativity: userData.fiveFactorsScores.Creativity,
          persistence: userData.fiveFactorsScores.Persistence,
          cooperativeness: userData.fiveFactorsScores.Cooperativeness,
          theme: userData.theme,
          shapeTheFuture: userData.fiveFactorsScores.Persistence + userData.fiveFactorsScores.Steadiness,
          deepResearch: userData.fiveFactorsScores.Creativity + userData.fiveFactorsScores.Cooperativeness,
          earnCustomerLoyalty: userData.fiveFactorsScores.Cooperativeness + userData.fiveFactorsScores.Sociability,
          creativityOriginality: userData.fiveFactorsScores.Creativity + userData.fiveFactorsScores.Persistence
        });
      } else {
        this.setState({ loading: false });
      }
      setTimeout(() => {

        if (window.location.hash) {
          document.getElementById(window.location.hash.replace('#', '')).scrollIntoView();
        }

      }, 1000);
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      //console.log('reload');
      this.updateData();
    } else {
      // console.log('no reload');
    }
  };

  calculateContentCompleteness = () => {
    const template = AppTemplates.default;
    let numComplete = 0;
    const numNeeded = template.softSkills.length;
    const profile = this.props.profile.getDefaultProfile();

    for (let i = 0; i < template.softSkills.length; i++) {
      const skillId = template.softSkills[i];
      if (profile.softSkillsAnswers && skillId in profile.softSkillsAnswers && profile.softSkillsAnswers[skillId].answer !== undefined) {
        numComplete++;
      }
    }

    return {
      complete: numComplete === numNeeded,
      numComplete,
      numNeeded
    };
  }

  calculateInfoCompleteness = () => {
    // const template = AppTemplates.default;
    let numComplete = 0;
    const numNeeded = 1;

    // if (AppConfig.isJobsMode()){
    //   if (template.jobs.resume.require){
    //     numNeeded++;
    //   }
    // }

    const profile = this.props.profile.getDefaultProfile();

    if (profile.fullName && profile.fullName !== '') {
      numComplete++;
    }

    // var regEx = /^\d{4}-\d{2}-\d{2}$/;
    // if (profile.myBirthday && profile.myBirthday !== "" && profile.myBirthday.match(regEx) != null) {
    //   numComplete++;
    // }


    // if (AppConfig.isJobsMode()){
    //   if (template.jobs.resume.require && profile.resumeURL){
    //     numComplete++;
    //   }
    // }

    return {
      complete: numComplete === numNeeded,
      numComplete,
      numNeeded
    };
  }

  printScreen = () => {
    window.print();
  }
  handleAnswer = async (answer, question_id) => {

    const data = {
      userAnswer: answer,
      userId: this.props.profile.uid,
      userRef: '',
      timeStamp: ''
    };
    await this.props.profile.addUserAnswerToDailyQuestion(data, question_id)
      .then(response => {
        console.log(response);
        if (response.status) {
          Notiflix.Notify.success(response.message);
          this.setState({ dailyAnswer: answer, isAnswered: true });
        }
      });
  }
  iconHandler = (customLink) => {
    if (customLink.includes('facebook')) {
      return 'facebook-square';
    } else if (customLink.includes('twitter')) {
      return 'twitter';
    } else if (customLink.includes('linkedin')) {
      return 'linkedin-square';
    } else if (customLink.includes('instagram')) {
      return 'instagram';
    } else if (customLink.includes('github')) {
      return 'github';
    } else if (customLink.includes('amazon.com/hz/wishlist')) {
      return 'amazon';
    } else {
      return 'link';
    }
  }
  getTraits(){
   
    const traitElements =this.state.chosenTraits
    
      traitElements.map(el=>{
        return(
         <span>{el} hello</span>
        )

      })

  
  }

  render() {
    const contentCompleteness = this.calculateContentCompleteness();
    const infoCompleteness = this.calculateInfoCompleteness();
    const that = this;



    if (!contentCompleteness.complete) {
      Notiflix.Report.warning(
        'You&apos;re not quite done!',
        `${contentCompleteness.numComplete} / ${contentCompleteness.numNeeded} Skills Completed`,
        'Take me there',
        () => {
          that.setState({ redirectRoute: ROUTES.MY_CONTENT });
        }
      );
    }
    else if (!infoCompleteness.complete) {
      Notiflix.Report.warning(
        'You&apos;re not quite done!',
        'More profile info needed',
        'Take me there',
        () => {
          that.setState({ redirectRoute: ROUTES.MY_INFO });
        }
      );
    }

    if (this.state.redirectRoute !== null) {
      return <Redirect to={this.state.redirectRoute} />;
    }

    // chartData object for Mission Statement & Values component 
    const chartData =
    {
      labels: [this.state.businessValue?.title1, this.state.businessValue?.title2, this.state.businessValue?.title3, this.state.businessValue?.title4],
      datasets: [
        {
          label: 'Your Profile',
          data: [
            this.state.shapeTheFuture,
            this.state.earnCustomerLoyalty,
            this.state.creativityOriginality,
            this.state.deepResearch
          ],
          backgroundColor: 'rgba(100, 62, 166, 0.5)',
          borderColor: 'rgba(100, 62, 166, 1)',
          borderWidth: 1,
        },
      ],
    };
    //chartOptions object for Mission Statement & Values component
    const chartOptions = {

      scales: {
        r: {
          suggestedMin: 0,
          suggestedMax: 6
        }

      },
      layout: {
        padding: {
          left: 20,
          right: 20
        },

      }
    };



    // const uid = this.props.profile.uid;
    //  if (!this.props.profile.data?.profiles?.default?.email || this.props.profile.data?.profiles?.default?.email == "") {
    //   return <Redirect to={ROUTES.HOME} />
    //   }

    if (this.state.loading) return (
      <div className="spinner-container d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>

    );
    console.log(this.state.profile)
    if (!this.state.profile || !this.state.profile.data) return (<FourOFour></FourOFour>);
    return (

      <>

        <div className="insightsPage">
          <Layout>
            <div className="insightsBox">

              <AuthDataContext.StreamConsumer requireAccount={true}>
                {profile => (

                  <>

                    <InsightsSideBar
                      image={profile.data.profiles.default?.profilePicture}
                      name={profile.data.profiles.default?.fullName}
                      profile={profile}
                    />


                    <div className="wrapper">

                      <FadeDown className="my-auto">

                        <div className="profile-box">

                          <div className="picture">
                          {(this.state.profile.data.profiles.default?.profilePicture==="https://ramcotubular.com/wp-content/uploads/default-avatar.jpg"||this.state.profile.data.profiles.default?.profilePicture==="/images/default-avatar.svg")?
                            <img className="profileImage" style={{ backgroundColor: this.state.color }} src='/assets/media/profile/default-avatar.jpeg' alt="www.you.com" />:
                            <img className="profileImage" style={{ backgroundColor: this.state.color }} src={this.state.profile.data.profiles.default?.profilePicture || RANDOM_PROFILE_PLACEHOLDER} alt="www.you.com" />}
                            {(this.props.profile.uid === this.props.match.params.id) &&
                              <p>
                                <div className="edit">
                                  <a href={'/myinfo'}>
                                    <img src="/assets/media/profile/pencil.png" alt="" />
                                  </a>
                                </div>
                              </p>
                            }

                          </div>
                          <div className="infoWrapper">
                            <div className="userName">
                              <strong>{this.state.profile.data.profiles.default?.fullName} </strong>
                              {(this.props.profile.uid === this.props.match.params.id) ?
                                <div>
                                  <button name="colorModal" onClick={this.handleShow}>My Vibe</button>
                                  <Modal
                                    show={this.state?.colorModal}
                                    onHide={() => this.setState({ colorModal: false })}
                                    className="colorModal"
                                    backdrop="static"
                                    centered>

                                    <Modal.Header closeButton />

                                    <Modal.Body>
                                      <h3>How are you today?</h3>
                                      <p>Choose the color of your mood</p>

                                      <form onSubmit={this.handleColorSubmit} className="colorForm">
                                        <div className="colorInputs">

                                          <label style={{ backgroundColor: '#0000FF' }}>
                                            <input

                                              type="radio"
                                              value="#0000FF"
                                              checked={this.state.value === '#0000FF'}
                                              onChange={this.handleColorChange}
                                            />
                                            <img src="/assets/colorIcons/icons8-sad-48.png" /></label>

                                          <label style={{ backgroundColor: '#008000' }}>
                                            <input

                                              type="radio"
                                              value="#008000"
                                              checked={this.state.value === '#008000'}
                                              onChange={this.handleColorChange}
                                            />
                                            <img src="/assets/colorIcons/icons8-happy-48.png" /></label>
                                          <label style={{ backgroundColor: '#FFFF00' }}>
                                            <input

                                              type="radio"
                                              value="#FFFF00"
                                              checked={this.state.value === '#FFFF00'}
                                              onChange={this.handleColorChange}
                                            />
                                            <img src="/assets/colorIcons/icons8-lol-48.png" /></label>



                                        </div>
                                        <button type="submit" value="Submit">Submit</button>
                                      </form>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                                : ''}
                                </div>
                                {/* <div>Awarded points: {this.state.profile.data.profiles.default?.pointsAwarded}</div> */}
                            <div className="user-info">
                              <div className="name-location">



                                <p>Location: <b>{this.state.profile.data.profiles.default?.myLocation || '--'}</b></p>


                                {(profile.data.company.name !== "open") &&
                                  <p>Years: <b>{this.state.profile.data.profiles.default?.joiningDate && this.state.profile.data.profiles.default?.joiningDate === '' ? 'see My Info' : getJoiningYears(this.state.profile.data.profiles.default?.joiningDate)} </b></p>}




                              </div>

                              <div className="team-role">

                                {(profile.data.company.name !== "open") ?
                                  <p>Team: <b>{this.state.profile.data.profiles.default?.myTeam || '--'}</b>  </p>
                                  : <p className="quote" style={{ fontSize: "1vw" }}>"{this.state.dailyQuote}"</p>
                                }
                                {(profile.data.company.name !== "open") ?
                                  <p>Role:  <b>{this.state.profile.data.profiles.default?.myRole || '--'}</b></p>
                                  : <p className="quote" style={{ fontSize: "1.2vw" }}>- {this.state.dailyQuoteAuthor}</p>
                                }

                              </div>
                            </div>
                            <div className="customLinks">
                              {/* {(this.state.profile.data.profiles?.default?.email && this.state.profile.data.profiles.default.email !== '') && 
                                  <a 
                                    href={'mailto:' + this.state.profile.data.profiles.default.email} 
                                    className="icon-link">
                                    <FontAwesome name="envelope" style={{ color: 'white'}}/>
                                  </a>
                                } */}
                                <div>
                              {(this.state.profile?.data?.profiles?.default?.customLinks?.length > 0) &&
                                this.state.profile?.data?.profiles?.default?.customLinks.map(customLink =>
                                  (customLink!=='' &&
                                    <a
                                      href={customLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="icon-link"
                                      key={customLink}>
                                      <FontAwesome name={this.iconHandler(customLink)} style={{ color: 'white' }} />
                                    </a>
                                  )
                                )

                              }
                                </div>
                                <div><button name="infoModal" onClick={this.handleShow}>More about {this.state.profile?.data?.profiles?.default?.fullName.split(' ')[0]}</button></div>
                                {(this.props.profile.uid === this.props.match.params.id) ?
                                <Modal
                                    show={this.state.infoModal}
                                    onHide={() => this.setState({ infoModal: false })}
                                    className="infoModal"
                                    backdrop="static"
                                    centered>

                                    <Modal.Header closeButton />

                                    <Modal.Body>
                                      <h4>More about {this.state.profile?.data?.profiles?.default?.fullName.split(' ')[0]}</h4>
                                      

                                      <form onSubmit={this.handleInfoSubmit} className="infoForm">
                                        <div className="infoInputs">
                                          <form id='info'>
                                            <textarea
                                              id='textArea'
                                              name='textArea'
                                              spellCheck={true}
                                              value={this.state.textArea}
                                              placeholder={this.state.profile?.data?.profiles?.default?.insights.moreInfo}
                                              onChange={this.handleTextAreaChange} />
                                            <p className='textAreaCounter'>{this.returnattributesLimit() + ' chars left'}</p>
                                           
                                          </form>
                                        </div>
                                        <button type="submit" value="Submit">Submit</button>
                                      </form>
                                    </Modal.Body>
                                  </Modal>:
                                   <Modal
                                   show={this.state.infoModal}
                                   onHide={() => this.setState({ infoModal: false })}
                                   className="infoModal"
                                   backdrop="static"
                                   centered>

                                   <Modal.Header closeButton />

                                   <Modal.Body>
                                     <h4>More about {this.state.profile?.data?.profiles?.default?.fullName.split(' ')[0]}</h4>
                                     
                                    {this.state.profile?.data?.profiles?.default?.insights.moreInfo?
                                     <p>{this.state.profile?.data?.profiles?.default?.insights.moreInfo}</p>: 
                                     <p>No additional information added yet</p>}
                                   </Modal.Body>
                                 </Modal>
                              }


                            </div>
                            {(this.state.chosenTraits?.length>0)?
                            <div style={{marginTop: '10px'}}>Highlighted Traits:  {this.state.chosenTraits.map(trait=>{
                              let styleColor;
                              if(trait==="Mentorship") styleColor = "yellow";
                              if(trait === "Leadership")  styleColor = "blue";
                              if(trait==="Empathetic") styleColor = "green";
                              if(trait ==="Collaborative") styleColor="red";
                              if(trait ==="Entrepreneurialism") styleColor="purple";
                              return(
                                <span style={{color: styleColor}}> {trait} </span>
                              )
                            })}</div>:''}
                          </div>


                        </div>

                      </FadeDown>

                      <FadeUp className="my-auto">
                        <h1><b>{this.state.profile.data.profiles.default?.fullName.split(' ')[0]}'s Profile</b></h1>
                        {this.state.showDailyQuestion && !this.state.isAnswered && (this.props.profile.uid === this.props.match.params.id) ?
                          <div className="sixAttributes">
                            <div className="title">
                              <span><b>Daily Question</b></span>
                            </div>

                            <section>
                              <DailyQuestion profile={profile}
                                handleAnswer={this.handleAnswer}
                                dailyQuestion={this.state.dailyQuestion}
                              />
                            </section>
                          </div>
                          : ''}


                        <div id="sixattributes">
                          <div className="sixAttributes" style={{marginTop: '90px'}}>
                            <div className="title">
                              <span><b>My Reflections</b></span>
                            </div>

                            <section>
                              <Cards
                                flip={false}
                                edit={false}
                                template={[
                                  
                                  'happy_place',
                                  'power_to_change',
                                  'workplace',
                                  'favorite_hobby',
                                  'perception',
                                  'curiosity'
                                ]}
                                softSkillsAnswers={this.state.profile.data.profiles.default?.softSkillsAnswers}
                                profile={profile} />

                            </section>

                          </div>

                        </div>  
                        <div id="affinitygroups">
                          <AffinityGroups props={this.props} profile={this.state.profile} affinityGroups={this.state.affinityGroups} affinityImage={this.state.affinityImage} />
                        </div>
                        {(profile.data.company.name !== "open") &&
                          <div className="vibe-innovate">
                            <div id="innovations">
                              <Innovations
                                props={this.props}
                                profile={this.state.profile}
                                innovationAnswers={this.state.profile.data.profiles.default?.innovationAnswers}
                              />
                            </div>
                          </div>}
                        <div className="vibe-innovate">
                          <div id="activities">
                            <MyVibe
                              props={this.props}
                              profile={this.state.profile}
                              vibeAnswers={this.state.profile.data.profiles.default?.vibeAnswers}
                            />
                          </div>
                        </div>
                    




                        {/* <div className="myProfile">
                        <div className="title">
                          <a id="personalsummary">
                            <span><b>Personal Summary</b></span>
                          </a>
                        </div>
                        <section>
                          <p>
                            <b>Sociability - “Outgoing” </b>scorers tend to be talkative, action-oriented, and enjoy interacting with others. 
                            They tend to be described by others as assertive, and more energetic and cheerful in disposition. This makes them 
                            ideal for interfacing with clients and customers. Because “Outgoing” scorers also tend to like 
                            excitement and seek stimulation, they may be prone to boredom in repetitive or low-energy tasks.
                          </p>
                          <p>
                            <strong>Steadiness - “Secure” </strong>scorers tend to possess a general sense of well-being and are less prone to worry. These individuals 
                            might be described by others as being even-tempered and calm in the face of stressful...
                          </p>
                    
                        </section>
                      </div> */}

                        {(profile.data.company.name !== "open") &&
                          <div id="missionstatement">
                            <MissionStatement chartData={chartData} chartOptions={chartOptions} profile={profile.data} businessValue={this.state.businessValue} />
                          </div>
                        }
                        <div id="fivefactors">
                       
                          {/* <FiveFactors 
                            sociability={this.state.sociability}
                            steadiness={this.state.steadiness}
                            creativity={this.state.creativity}
                            persistence={this.state.persistence}
                            cooperativeness={this.state.cooperativeness}
                            profile={profile}
                            chosenTraits={this.state.chosenTraits}
                            viewer={this.props.match.params.id}
                          /> */}
                          <NewGraph 
                              sociability={this.state.sociability}
                              steadiness={this.state.steadiness}
                              creativity={this.state.creativity}
                              persistence={this.state.persistence}
                              cooperativeness={this.state.cooperativeness}
                              profile={profile}
                              chosenTraits={this.state.chosenTraits}
                              viewer={this.props.match.params.id} />

                        </div>
                  
               
                  
               
                 

                        <nav className="footerNav">
                          <CopyToClipboard text={process.env.REACT_APP_LOCAL + ROUTES.PROFILEid + this.state.profile.uid}
                            onCopy={() => Notiflix.Notify.success('Link copied to clipboard')}>
                            <button>
                              <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.65 35C6.65 23.03 11.515 13.3 17.5 13.3H31.5V0H17.5C7.84 0 0 15.68 0 35C0 54.32 7.84 70 17.5 70H31.5V56.7H17.5C11.515 56.7 6.65 46.97 6.65 35ZM21 42H49V28H21V42ZM52.5 0H38.5V13.3H52.5C58.485 13.3 63.35 23.03 63.35 35C63.35 46.97 58.485 56.7 52.5 56.7H38.5V70H52.5C62.16 70 70 54.32 70 35C70 15.68 62.16 0 52.5 0Z" fill="white" />
                              </svg>
                              <h3>Share</h3>
                            </button>
                          </CopyToClipboard>
                          <span>
                            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M62.2222 35V62.2222H7.77778V35H0V62.2222C0 66.5 3.5 70 7.77778 70H62.2222C66.5 70 70 66.5 70 62.2222V35H62.2222ZM38.8889 37.6056L48.9611 27.5722L54.4444 33.0556L35 52.5L15.5556 33.0556L21.0389 27.5722L31.1111 37.6056V0H38.8889V37.6056Z" fill="white" />
                            </svg>
                            <h3>Download</h3>
                          </span>
                          <span>
                            <button onClick={this.printScreen}>
                              <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M59.5 19.4444H10.5C4.69 19.4444 0 24.6556 0 31.1111V54.4444H14V70H56V54.4444H70V31.1111C70 24.6556 65.31 19.4444 59.5 19.4444ZM49 62.2222H21V42.7778H49V62.2222ZM59.5 35C57.575 35 56 33.25 56 31.1111C56 28.9722 57.575 27.2222 59.5 27.2222C61.425 27.2222 63 28.9722 63 31.1111C63 33.25 61.425 35 59.5 35ZM56 0H14V15.5556H56V0Z" fill="white" />
                              </svg>
                              <h3>Print</h3>
                            </button>
                          </span>
                        </nav>
                      </FadeUp>
                    </div>
                  </>
                )}




              </AuthDataContext.StreamConsumer>
            </div>
          </Layout>
        </div>


      </>
    );
  }
}




const condition = (profile) => {
  if (AppConfig.isJobsMode()) {
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
};

export default compose(
  withAuthorization(condition),
)(InsightsPage);






