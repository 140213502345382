import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import React, { Component } from 'react';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import Cards from '../../components/Cards';
import Layout from '../../components/Layout';
import './MyContent.scss';
import Controllers from '../../components/Controllers';
import * as ROUTES from '../../constants/routes';
import AppTemplates from '../../constants/apptemplates';
import styled, { keyframes } from 'styled-components';
import { fadeInDown, fadeInUp } from 'react-animations';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import AppConfig from '../../components/App/AppConfig'
import { Container, Jumbotron } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { postProfile } from '../../utils';

const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;

const INITIAL_STATE = {
  userId: '',
};

class MyContentPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.state.pingEngine ={

    email: 'this@that.com',
    full_name: 'name',
    company: 'company',
    user_id: 'userid',
    power_to_change: {
      text: 'blank',
      url: 'https://media2.giphy.com/media/iicDrNGWxHmDrIni6j/giphy-downsized-medium.gif?cid=c90613e0aujl964osi8gysotsgf48rlotebkqxzn9e1mvk1p&ep=v1_gifs_search&rid=giphy-downsized-medium.gif&ct=g',
    },
    workplace: {
      text: 'blank',
      url: 'https://media2.giphy.com/media/iicDrNGWxHmDrIni6j/giphy-downsized-medium.gif?cid=c90613e0aujl964osi8gysotsgf48rlotebkqxzn9e1mvk1p&ep=v1_gifs_search&rid=giphy-downsized-medium.gif&ct=g',
    },
    perception: {
      text: 'blank',
      url: 'https://media2.giphy.com/media/iicDrNGWxHmDrIni6j/giphy-downsized-medium.gif?cid=c90613e0aujl964osi8gysotsgf48rlotebkqxzn9e1mvk1p&ep=v1_gifs_search&rid=giphy-downsized-medium.gif&ct=g',
    },
    happy_place: {
      text: 'blank',
      url: 'https://media2.giphy.com/media/iicDrNGWxHmDrIni6j/giphy-downsized-medium.gif?cid=c90613e0aujl964osi8gysotsgf48rlotebkqxzn9e1mvk1p&ep=v1_gifs_search&rid=giphy-downsized-medium.gif&ct=g',
    },
    favorite_hobby: {
      text: 'blank',
      url: 'https://media2.giphy.com/media/iicDrNGWxHmDrIni6j/giphy-downsized-medium.gif?cid=c90613e0aujl964osi8gysotsgf48rlotebkqxzn9e1mvk1p&ep=v1_gifs_search&rid=giphy-downsized-medium.gif&ct=g',
    },
    curiosity: {
      text: 'blank',
      url: 'https://media2.giphy.com/media/iicDrNGWxHmDrIni6j/giphy-downsized-medium.gif?cid=c90613e0aujl964osi8gysotsgf48rlotebkqxzn9e1mvk1p&ep=v1_gifs_search&rid=giphy-downsized-medium.gif&ct=g',
    },
    // company: "Company",
    birthday: '1-1-2000',
    location: 'here',
    job_title: 'blank',
    team: 'blank',
    profile_picture: 'blank',
    raw_attributes: 'blank'
    }
  };
  static defaultProps = {
    match: '',
  }
  

  componentDidMount = () => {
    let profilePingInterval
    try{
      postProfile(this.state.pingEngine)
      profilePingInterval = setInterval(async()=>await postProfile(this.state.pingEngine), 1000*5*60)
     
    }
    catch (error){
      console.log(error.message, 'error')
    }
   ;
   return ()=>clearInterval(profilePingInterval)
    
  }

  logData = (category, data, type, extra) => {
    // const logData = f.functions().httpsCallable('logData');
    // logData({category: category, data: data, type: type, extra: extra});
  }

  render() {
    
if (!this.props.profile.data?.profiles?.default?.email || this.props.profile.data?.profiles?.default?.email === "") {
  return <Redirect to={ROUTES.HOME} />
  }
    return (
      <Layout>
        <ScrollTopOnPageLoad/>
        <Container>
          <div className='MyContent'>
            <AuthDataContext.StreamConsumer requireAccount={true}>
              {profile => (
                <>
                  <FadeDown>
                    <Jumbotron className='text-center'>
                      <span className='heading'>PROFILE QUESTIONS</span>
                      {AppConfig.getCodeSnippetOption('myContentIntro')}
                    </Jumbotron>
                    <Controllers 
                      backRoute={ROUTES.HOME} 
                      nextRoute={ROUTES.MY_INFO}
                      className='top-controller' />
                  </FadeDown>
                  <FadeUp>
                    <Cards 
                      flip={true} 
                      edit={true} 
                      template={AppTemplates.default.softSkills}
                      softSkillsAnswers={profile.data.profiles.default.softSkillsAnswers} 
                      profile={profile}/>
                  </FadeUp>
                </>
              )}
            </AuthDataContext.StreamConsumer>
            <Controllers backRoute={ROUTES.HOME} nextRoute={ROUTES.MY_INFO} />
          </div>
        </Container>
      </Layout>
    );
  }
}


const condition = (profile) => {
  if (AppConfig.isJobsMode()){
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
}

export default compose(
  withAuthorization(condition),
)(MyContentPage);
